import * as React from "react";
import { Button, Container, Link, Typography, makeStyles } from "@toll/ui";
import { WHITE } from "@toll/ui/jss/colors";

const TOLLMØRKEBLÅ = "#12404f";

const useStyles = makeStyles({
  cookiesInfo: {
    background: TOLLMØRKEBLÅ,
    bottom: 0,
    color: WHITE,
    padding: "1.5em 0",
    position: "fixed",
    width: "100%"
  },
  cookiesInfoContentMargin: {
    marginBottom: "0.75em",
  },
  cookiesLink: {
    color: WHITE
  },
  overrideButton: {
    background: "transparent",
    color: WHITE
  }
});

const CookiesInfo = (props) => {
  const {onSubmit} = props;
  const classes = useStyles();
  return (
    <div className={classes.cookiesInfo}>
      <Container>
        <Typography variant={"h4"} gutterBottom>We use cookies</Typography>
        <Typography gutterBottom>
          Norwegian customs use cookies that are important for the service to work properly.
          We use cookies to improve our digital services.
          All data stored is anonymized.
        </Typography>
        <div className={classes.cookiesInfoContentMargin}>
          <Link href="https://www.toll.no/en/about-norwegian-customs/privacy/privacy-at-toll.no/"
                className={classes.cookiesLink} underline={"always"}>
            Learn more about how and what we use cookies for.
          </Link>
        </div>
        <Button onClick={onSubmit} variant={"contained"} color={"secondary"}>ACCEPT COOKIES</Button>
      </Container>
    </div>
  );
};

export default CookiesInfo;
