import { root } from "../server/paths";

function apiFetch(path) {
  return fetch(root + path).then(response => {
    if (!!response.error) {
      console.error(response.error);
    }
    else {
      try {
        return response.json();
      } catch (e) {
        console.error(e);
      }
    }
  });
}

export { apiFetch };
