import React, { useState, useEffect } from 'react';
import { Alert, Typography } from "@toll/ui";
import { apiFetch } from "../util/fetch";

export default () => {

  const [driftsmeldinger, setDriftsmeldinger] = useState([]);
  const [statusmeldinger, setStatusmeldinger] = useState([]);

  useEffect(() => {
    apiFetch('/messages').then((response) => {
      try {
        setDriftsmeldinger(response.filter(alert => !!alert.message));
      } catch (e) {
        console.error(e);
      }
    });
  }, []);

  useEffect(() => {
    apiFetch('/status').then((response) => {
      if (
        response.redis.status !== 'OK' ||
        response.api.ar.status !== 'OK' ||
        response.api.info.status !== 'OK'
      ) {
        setStatusmeldinger([{
          severity: 'error',
          message: 'We are experiencing some issues with our service.'
        }]);
      }
    });
  }, []);

  return (
    driftsmeldinger.concat(statusmeldinger).map((alert, i) => {
      return (
        <div style={{marginBottom: "10px"}} key={i}>
          <Alert severity={!!alert.severity ? alert.severity : 'error'}>
            <Typography>{alert.message}</Typography>
          </Alert>
        </div>
      );
    })
  );
}
