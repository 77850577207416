import React, { useState } from 'react';
import { Container, Grid, Typography } from '@toll/ui';
import Form from './Form';
import Fail from './Fail';
import Success from './Success';
import Header from './Header';
import Driftsmeldinger from './Driftsmeldinger';
import SnackbarWrap, { createMeldingWrapper, createContext } from './SnackbarWrap';
import * as gtag from "../util/gtag";

const STATE_INITIAL = {step: 'form', result: null};

const SnackbarContext = createContext('messages');
const wrapperid = 'myndighet' + Date.now();
const meldinger = createMeldingWrapper(wrapperid);

export default () => {
  const [state, setState] = useState(STATE_INITIAL);

  const checkOther = () => {
    gtag.event(gtag.CLICK_CHECK_OTHER);
    setState(STATE_INITIAL);
  };

  const checkResult = (result) => {
    if (result.ok && result.ok === 'success') {
      gtag.event(gtag.CHECK_OK);
      setState({
        step: 'success',
        result: result
      });
    }
    else {
      gtag.event(gtag.CHECK_FAIL);
      setState({
        step: 'fail',
        result: result
      });
    }
  };

  return (
    <SnackbarContext.Provider value={meldinger}>
      <Header />
      <Container maxWidth="md">
        <Driftsmeldinger />
        <Container maxWidth="sm">
          <Grid container item>
            <Typography gutterBottom variant={"h1"}>
              Movement Certificate EUR.1 Authentication tool
            </Typography>
          </Grid>
          <Grid container direction={"column"} spacing={4}>
            {state.step === 'form' && <Form
              checkResult={checkResult}
            />}
            {state.step === 'success' && <Success
              result={state.result}
              checkOther={checkOther}
            />}
            {state.step === 'fail' && <Fail
              result={state.result}
              checkOther={checkOther}
            />}
          </Grid>
        </Container>
      </Container>
      <SnackbarWrap wrapperid={wrapperid} />
    </SnackbarContext.Provider>
  );
};
