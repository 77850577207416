import React, { useState } from 'react';
import './App.css';
import { ThemeProvider, tollTheme } from '@toll/ui';
import Page from './components/Page';
import CookiesInfo from "./components/CookiesInfo";
import CookiesContext from "./components/CookiesContext";
import { Footer } from "@toll/ui";

const COOKIE_ACCEPTED = 'cookieAccepted';

const setCookie = (cookie, value, expires) => {
  const c = [`${cookie}=${encodeURIComponent(value)}`];
  if (expires) {
    c.push(`expires=${expires.toUTCString()}`);
  }
  c.push('path=/');
  document.cookie = c.join(';');
};

const getCookie = (name) => {
  return document.cookie.split(';').reduce((a, kv) => {
    const [key, value] = kv.split('=');
    if (key.trim() === name) return value.trim();
    return a;
  }, null);
};

function App() {
  const [showCookieInfo, setShowCookieInfo] = useState(getCookie(COOKIE_ACCEPTED) === 'ok' ? false : true);

  const cookiesContext = {
    cookiesAccepted: !showCookieInfo
  };

  return (
    <ThemeProvider theme={tollTheme}>
      <CookiesContext.Provider value={cookiesContext}>
        <div style={{display: 'flex', flexDirection: 'column', height: "100%"}}>
          <div style={{flexGrow: 1}}>
            <Page/>
          </div>
          <div>
            <Footer language="en"/>
          </div>
        </div>
        {showCookieInfo &&
        <CookiesInfo onSubmit={() => {
          setCookie(COOKIE_ACCEPTED, 'ok', new Date(Date.now() + 10 * 365 * 24 * 3600000));
          setShowCookieInfo(false);
        }}/>
        }
      </CookiesContext.Provider>
    </ThemeProvider>
  );
}

function hello() {
  fetch('/version.json')
    .then(response => response.json())
    .then(data => console.info('Versjon:', data));
}

hello();

export default App;
