import React from 'react';
import { Paper, CheckIcon, Link, withStyles } from "@toll/ui";

const styles = () => ({
  link: {
    padding: '7px 0',
    cursor: 'pointer'
  },
  icon: {
    marginRight: 12,
    padding: '7px 0',
    fontSize: 22,
    opacity: 0.9,
  },
  inn: {
    backgroundColor: "#d9f1f4",
    display: 'flex',
    padding: "1em"
  },
  checkOther: {}
});

export default withStyles(styles)(props => {

  const dato = props.result.date.split('-').reverse().join('-');

  return (
    <div className={props.classes.out}>
      <div className={props.classes.link}>
        <Link
          onClick={props.checkOther}
        >
          Check other
        </Link>
      </div>
      <Paper
        border="none"
      >
        <div className={props.classes.inn}>
          <div className={props.classes.icon}>
            <CheckIcon
              htmlColor="primary"
              fontSize="large"
            />
          </div>
          <div>
            <p>Match found!</p>
            <p>Norwegian customs hereby confirm the authenticity of the Movement Certificate EUR.1 with the reference
              number {props.result.ar} issued {dato}, {props.result.data.time}.</p>
            <p>Please be adviced that this does not confirm the originating status of the product.</p>
          </div>
        </div>
      </Paper>
    </div>
  );
});
