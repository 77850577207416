function push() {
  console.log('gtag', arguments);  // TODO: For debugging, skal fjernes
  if (window && window.dataLayer) {
    window.dataLayer.push(arguments);
  }
}

export function event(eventName, args) {
  if (args) {
    push('event', eventName, args);
  }
  else {
    push('event', eventName);
  }
}

export const CLICK_CHECK_OTHER = 'click_check_other';
export const CLICK_SEE_EXAMPLE = 'click_see_example';
export const CHECK_OK = 'check_ok';
export const CHECK_FAIL = 'check_fail';
export const RECAPTCHA_OK = 'recaptcha_ok';
export const RECAPTCHA_FAIL = 'recaptcha_fail';
