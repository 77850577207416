import React from 'react';
import { Paper, InfoOutlinedIcon, withStyles, Link } from "@toll/ui";

const styles = () => ({
  link: {
    padding: '7px 0',
    cursor: 'pointer',
  },
  icon: {
    marginRight: 12,
    padding: '7px 0',
    fontSize: 22,
    opacity: 0.9,
  },
  inn: {
    backgroundColor: "#fdd",
    display: 'flex',
    padding: "1em"
  }
});

export default withStyles(styles)(props => {

  const dato = props.result.date.split('-').reverse().join('-');

  return (
    <div className={props.classes.out}>
      <div className={props.classes.link}>
        <Link
          onClick={props.checkOther}
        >
          Check other
        </Link>
      </div>
      <Paper
        border="none"
      >
        <div className={props.classes.inn}>
          <div className={props.classes.icon}>
            <InfoOutlinedIcon
              color="error"
              fontSize="large"
            />
          </div>
          <div>
            <p>No match found!</p>
            <p>We did not find a match between the reference number {props.result.ar} and date of
              issue {dato}.</p>
            {props.result.serverdate === props.result.date && (
              <p>Please try again in some minutes if the enquired certificate has just recently been issued.
                The system may require some minutes to be updated.</p>
            )}
          </div>
        </div>
      </Paper>
    </div>
  );
});
