const API = '/eur1-gui';
const TEST = '/test';

const api = [
  ['checkCert', '/check/:ar/:date'],
  ['recaptcha', '/cre/:hash'],
  ['status', '/status/:path?'],
  ['status', '/info/:path?'],
  ['health', '/health/:path?'],
  ['user', '/user'],
  ['messages', '/messages'],
].map(p => [p[0], API + p[1]]);

const test = [
  ['info', '/info/:path?'],
  ['missing', '/missing'],
  ['hello', '/hello'],
  ['testserver', '/testserver/:mode?']
].map(p => [p[0], TEST + p[1]]);

module.exports = {
  paths: api.concat(test),
  root: API,
};
