import React from 'react';
import { Container, Grid, Link } from "@toll/ui";
import TolletatenLogo from "../svg/TolletatenLogo";

export default () => {
  return <header style={{marginTop:"2em"}}>
    <Container>
      <Grid container justify={"space-between"} alignItems={"center"}>
        <Link href={'https://www.toll.no/en/corporate/'}>
          <TolletatenLogo/>
        </Link>
      </Grid>
    </Container>
  </header>;
}
