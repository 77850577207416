import React, { useCallback, useEffect, useState } from "react";
import { Alert, CloseIcon, IconButton, Snackbar, Typography } from "@toll/ui";

const DELAY_BETWEEN_MESSAGES = 200;
const AUTO_HIDE_DURATION = 6000;

const queuemap = {};
let snackOpen = false;

export const feilMelding = (wrapperid, msg) => {
  melding('error', wrapperid, msg);
};

export const advarselMelding = (wrapperid, msg) => {
  melding('warning', wrapperid, msg);
};

export const informasjonMelding = (wrapperid, msg) => {
  melding('info', wrapperid, msg);
};

export const suksessMelding = (wrapperid, msg) => {
  melding('success', wrapperid, msg);
};

export const melding = (severity, wrapperid, msg) => {
  document.dispatchEvent(new CustomEvent(wrapperid, {detail: {msg, severity}}));
};

export const __contextHash = {};

export const createContext = id => {
  const p = React.createContext(null);
  __contextHash[id] = p;
  return p;
}

export const getContext = id => __contextHash[id];

export const createMeldingWrapper = wrapperid => {
  return {
    feil: msg => feilMelding(wrapperid, msg),
    advarsel: msg => advarselMelding(wrapperid, msg),
    informasjon: msg => informasjonMelding(wrapperid, msg),
    suksess: msg => suksessMelding(wrapperid, msg),
    melding: (severity, msg) => melding(severity, wrapperid, msg),
  };
}

const SnackbarWrap = (props) => {

  const [state, setState] = useState(() => {
    return {
      open: false,
      title: '',
      header: '',
      body: '',
      severity: ''
    };
  }, []);

  const displayNextMessage = useCallback(
    () => {
      if (!snackOpen && queuemap[props.wrapperid].length > 0) {
        const detail = queuemap[props.wrapperid].shift();
        const {msg, severity} = detail;
        const newState = {
          ...state,
          open: true,
          title: msg.title || '',
          header: msg.header || '',
          body: msg.body || '',
          severity,
        };
        setState(newState);
      }
    }, [props.wrapperid, state]);

  const addToQueue = useCallback(
    (msg) => {
      queuemap[props.wrapperid] = queuemap[props.wrapperid].concat(msg);
      displayNextMessage();
    }, [displayNextMessage, props.wrapperid]);

  useEffect(() => {
    if (!queuemap[props.wrapperid]) {
      queuemap[props.wrapperid] = queuemap[props.wrapperid] || [];
      document.addEventListener(props.wrapperid, function (e) {
        addToQueue(e.detail);
      }, false);
    }
  }, [props.wrapperid, addToQueue]);

  useEffect(() => {
    snackOpen = state.open;
  }, [state]);

  const onClose = () => {
    const newState = {
      ...state,
      open: false,
    };
    setState(newState);
    setTimeout(() => {
      displayNextMessage();
    }, DELAY_BETWEEN_MESSAGES);
  };

  return (<Snackbar
    open={state.open}
    onClose={onClose}
    autoHideDuration={AUTO_HIDE_DURATION}
    anchor={{horizontal: props.horizontal || "center", vertical: props.vertical || "top"}}
  >
    <Alert
      fitContent
      title={state.title}
      severity={state.severity}
      action={
        <IconButton size="small" onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      }
    >
      <Typography variant="subtitle1">{state.header}</Typography>
      <Typography variant="body1">{state.body}</Typography>
    </Alert>
  </Snackbar>);
};

export default SnackbarWrap;
