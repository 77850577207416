import React from 'react';
import { Dialog } from '@toll/ui';


export default props => {

  const handleOnClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      onClose={handleOnClose}
      aria-labelledby="example picture"
      open={props.show}
      onBackdropClick={handleOnClose}
      fullWidth={true}
      maxWidth={"xl"}
    >
      <div onClick={handleOnClose}>
        <img src='/example.png' alt='AR code example' />
      </div>
    </Dialog>
  );
};
