import React from "react";
import { makeStyles } from "@toll/ui";

function SVG() {

  const w = 260;
  const h = 64;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={w + 'px'}
      height={h + 'px'}
      viewBox={`0 0 ${w} ${h}`}
      xmlSpace="preserve"
      fill={"#2E2D2C"}
    >
      <g>
        <g>
          <path d="M17.75,8.23v23.61h-6.8V8.23H3.46V2.86h21.76v5.38H17.75z"/>
          <path d="M42.15,32.35c-8.61,0-15.38-5.59-15.38-14.95c0-9.37,6.76-15,15.38-15s15.38,5.63,15.38,15
				C57.52,26.76,50.76,32.35,42.15,32.35z M42.15,8.19c-4.83,0-8.49,3.49-8.49,9.03c0,5.54,3.65,9.07,8.49,9.07s8.49-3.53,8.49-9.07
				C50.63,11.72,46.98,8.19,42.15,8.19z"/>
          <path d="M63.44,31.84V2.86h6.76v23.36h10.04v5.63H63.44z"/>
          <path d="M85.41,31.84V2.86h6.76v23.36h10.04v5.63H85.41z"/>
          <path d="M107.37,31.84V2.86h18.78v5.42h-12.01v5.92h9.64v5.42h-9.64v6.72h12.39v5.5H107.37z"/>
          <path d="M144.63,8.23v23.61h-6.8V8.23h-7.48V2.86h21.76v5.38H144.63z"/>
          <path d="M172.77,31.84L170.71,26h-10.33l-2.06,5.84h-7.06l10.92-29.03h7.06l10.92,29.03H172.77z M165.54,10.29
				l-3.61,10.42h7.23L165.54,10.29z"/>
          <path d="M193.18,8.23v23.61h-6.8V8.23h-7.48V2.86h21.76v5.38H193.18z"/>
          <path d="M205.57,31.84V2.86h18.78v5.42h-12.01v5.92h9.64v5.42h-9.64v6.72h12.39v5.5H205.57z"/>
          <path d="M250.26,31.84l-12.73-19.66c0,0,0.13,3.49,0.13,6.55v13.11h-6.64V2.86h7.27l12.43,19.49
				c0,0-0.13-3.28-0.13-6.34V2.86h6.64v28.99H250.26z"/>
        </g>
        <g>
          <path d="M13.12,60.73l-6.77-9.35c0,0,0.03,1.58,0.03,3.11v6.24H4.76V49h1.73l6.65,9.21c0,0-0.02-1.45-0.02-2.99V49
				h1.61v11.73H13.12z"/>
          <path d="M23.24,60.93c-3.3,0-6.05-2.29-6.05-6.05s2.75-6.05,6.05-6.05c3.3,0,6.05,2.29,6.05,6.05
				S26.53,60.93,23.24,60.93z M23.24,50.26c-2.4,0-4.37,1.72-4.37,4.59c0,2.86,1.99,4.59,4.37,4.59c2.4,0,4.37-1.73,4.37-4.59
				C27.6,51.98,25.63,50.26,23.24,50.26z"/>
          <path d="M37.69,60.73l-3.06-5.05h-1.26v5.05h-1.65V49h3.31c2.09,0,3.96,0.97,3.96,3.3c0,2.06-1.43,2.97-2.67,3.21
				l3.35,5.22H37.69z M36.55,50.77c-0.53-0.39-1.16-0.42-1.97-0.42h-1.21v3.98h1.21c0.82,0,1.45-0.03,1.97-0.41
				c0.53-0.39,0.75-0.87,0.75-1.58C37.29,51.64,37.07,51.14,36.55,50.77z"/>
          <path d="M52.61,60.75H51l-2.82-9.48l-2.81,9.48h-1.61L40.37,49h1.73l2.53,9.25l2.7-9.25h1.68l2.7,9.25L54.26,49h1.73
				L52.61,60.75z"/>
          <path d="M58.22,60.73V49h6.87v1.38h-5.22v3.5h4.93v1.39h-4.93v4.06h5.35v1.39H58.22z"/>
          <path d="M73.08,60.93c-3.35,0-5.98-2.41-5.98-6.07s2.63-6.03,5.97-6.03c3.01,0,4.3,1.58,4.74,2.04l-1.12,1.04
				c-0.7-0.82-1.79-1.67-3.62-1.67c-2.41,0-4.27,1.73-4.27,4.62s1.92,4.62,4.37,4.62c2.43,0,3.3-1.07,3.3-1.07v-2.6H73.3v-1.34h4.76
				v4.55C77.84,59.25,76.41,60.93,73.08,60.93z"/>
          <path d="M80.8,60.73V49h1.65v11.73H80.8z"/>
          <path d="M93.78,60.73l-1.21-3.06h-5.03l-1.21,3.06h-1.73l4.78-11.76h1.44l4.79,11.76H93.78z M90.06,51.02l-2.06,5.27
				h4.11L90.06,51.02z"/>
          <path d="M106.16,60.73l-6.77-9.35c0,0,0.03,1.58,0.03,3.11v6.24H97.8V49h1.73l6.65,9.21c0,0-0.02-1.45-0.02-2.99V49
				h1.61v11.73H106.16z"/>
          <path d="M120.78,60.93c-3.42,0-5.98-2.4-5.98-6.05c0-3.65,2.63-6.05,6-6.05c2.19,0,3.52,0.92,4.17,1.55l-0.7,1.33
				c-0.46-0.58-1.77-1.43-3.4-1.43c-2.53,0-4.37,1.82-4.37,4.57c0,2.75,1.85,4.57,4.37,4.57c1.55,0,2.74-0.59,3.5-1.43l0.71,1.21
				C124.35,60.02,123.01,60.93,120.78,60.93z"/>
          <path d="M135.18,59.51c-0.8,0.92-1.97,1.43-3.52,1.43c-1.56,0-2.74-0.51-3.52-1.43c-0.82-0.95-0.95-2.11-0.95-3.57
				V49h1.67v6.78c0,0.99,0.02,1.94,0.54,2.63c0.48,0.65,1.24,1.04,2.26,1.04c1.02,0,1.77-0.39,2.26-1.04
				c0.53-0.7,0.54-1.67,0.54-2.63V49h1.65v6.93C136.11,57.4,135.98,58.55,135.18,59.51z"/>
          <path d="M142.11,60.93c-2.06,0-3.23-1.33-3.79-1.97l0.97-1.11c0.59,0.8,1.7,1.68,2.97,1.68
				c1.28,0,2.29-0.73,2.29-1.97c0-1.22-1.31-1.7-1.9-1.94c-0.61-0.24-1.43-0.56-1.87-0.78c-1.21-0.56-1.94-1.5-1.94-2.87
				c0-2.09,1.9-3.13,3.77-3.13c1.92,0,3.04,1.17,3.37,1.51l-0.87,1.12c-0.58-0.61-1.43-1.26-2.58-1.26c-1.16,0-2.04,0.54-2.04,1.63
				s0.8,1.5,1.51,1.79l2.16,0.9c1.21,0.56,2.04,1.53,2.04,2.92C146.21,59.74,144.17,60.93,142.11,60.93z"/>
          <path d="M152.93,50.38v10.35h-1.65V50.38h-3.31V49h8.26v1.38H152.93z"/>
          <path d="M162.79,60.93c-3.3,0-6.05-2.29-6.05-6.05s2.75-6.05,6.05-6.05c3.3,0,6.05,2.29,6.05,6.05
				S166.08,60.93,162.79,60.93z M162.79,50.26c-2.4,0-4.37,1.72-4.37,4.59c0,2.86,1.99,4.59,4.37,4.59c2.4,0,4.37-1.73,4.37-4.59
				C167.15,51.98,165.18,50.26,162.79,50.26z"/>
          <path d="M181.76,60.73l-0.77-9.26h-0.05l-3.59,9.37h-0.93l-3.59-9.37h-0.05l-0.75,9.26h-1.63L171.46,49h2.04
				l3.38,9.06h0.03L180.3,49h2.02l1.07,11.73H181.76z"/>
          <path d="M189.12,60.93c-2.06,0-3.23-1.33-3.79-1.97l0.97-1.11c0.59,0.8,1.7,1.68,2.97,1.68
				c1.28,0,2.29-0.73,2.29-1.97c0-1.22-1.31-1.7-1.9-1.94c-0.61-0.24-1.43-0.56-1.87-0.78c-1.21-0.56-1.94-1.5-1.94-2.87
				c0-2.09,1.9-3.13,3.77-3.13c1.92,0,3.04,1.17,3.37,1.51l-0.87,1.12c-0.58-0.61-1.43-1.26-2.58-1.26c-1.16,0-2.04,0.54-2.04,1.63
				s0.8,1.5,1.51,1.79l2.16,0.9c1.21,0.56,2.04,1.53,2.04,2.92C193.22,59.74,191.18,60.93,189.12,60.93z"/>
        </g>
      </g>
    </svg>
  );
}

const useStyles = makeStyles({
  logoContainer: {
    marginBottom: "1rem",
  },
});

const TolletatenLogo = () => {

  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <SVG/>
    </div>
  );
};

export default TolletatenLogo;

