import React, { useEffect, useState, useContext } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  DatePicker,
  Grid, Link,
  PickerWrapper,
  TextField,
} from '@toll/ui';

import { apiFetch } from "../util/fetch";
import CookiesContext from "./CookiesContext";
import ExamplePicture from "./ExamplePicture";
import { getContext } from "./SnackbarWrap";
import * as gtag from "../util/gtag";

const DATEFORMAT_USERS = 'DD-MM-YYYY';
const DATEFORMAT_BACKEND = 'YYYY-MM-DD';

const SITE_KEY = "6LedoeEZAAAAAHOvxJgCY1R9h76yvjzC1FsSydoT";

const SHOW_RECAPTCHA = 'R';
const SHOW_BUTTON = 'B';

const MAX_AR_LENGTH = 20;

export default props => {
  const {checkResult} = props;

  const [recaptchaOk, setRecaptchaOk] = useState(0);
  const [showExample, setShowExample] = useState(false);

  const [date, setDate] = useState(null);
  const [arNumber, setArNumber] = useState('AR');

  const meldinger = useContext(getContext('messages'));

  useEffect(() => {
    apiFetch('/user').then((response) => {
      try {
        setRecaptchaOk(response.recaptcha || 0);
      } catch (e) {
        console.error(e);
      }
    });
  }, []);

  const dateString = (date && date.isValid && date.isValid() && date.format(DATEFORMAT_USERS)) || '';

  const valid = (dateString.length === DATEFORMAT_USERS.length && arNumber.length > 8);

  const cookiesContext = useContext(CookiesContext);

  const changeDate = (e) => {
    setDate(e);
  };
  const changeAr = (e) => {
    const v = 'AR' + e.target.value.substr(2)
      .replace(/[^0-9]/g, '').substr(0, MAX_AR_LENGTH);
    setArNumber(v);
  };

  const onCheckAutenthication = () => {
    if (valid) {
      apiFetch(`/check/${arNumber}/${date.format(DATEFORMAT_BACKEND)}`)
        .then(data => {
          if (data) {
            if (data.error) {
              meldinger.feil({
                title: 'Error',
                body: 'An error occurred.',
              });

            }
            else {
              checkResult(data);
            }
          }
        });
    }
  };

  const onRecaptchaChange = change => {
    apiFetch(`/cre/${change}`)
      .then(data => {
        if (data.msg === 'ok') {
          gtag.event(gtag.RECAPTCHA_OK);
          setRecaptchaOk(Date.now());
        }
        else {
          gtag.event(gtag.RECAPTCHA_FAIL);
          setRecaptchaOk(0);
          if (data.msg === 'error') {
            meldinger.feil({
              title: 'Error',
              body: 'An error occurred verifying recaptcha.',
            });
          }
        }
      });
  };

  let show = '';
  if (cookiesContext.cookiesAccepted) {
    if (recaptchaOk <= 0) show = SHOW_RECAPTCHA;
    else show = SHOW_BUTTON;
  }

  return (
    <>
      <Grid item xs={12}>
        <PickerWrapper language="norwegian">
          <DatePicker
            name="date"
            label="Date of issue"
            format="DD-MM-YYYY"
            placeholder="Enter date DD-MM-YYYY"
            value={date}
            onChange={changeDate}
            fullWidth
          />
        </PickerWrapper>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <TextField
            margin="dense"
            label="Archive reference number (AR + digits)"
            name="ar"
            value={arNumber}
            onChange={changeAr}
            fullWidth
          />
          <Link
            style={{cursor: 'pointer', marginTop: '.5em'}}
            onClick={() => {
              gtag.event(gtag.CLICK_SEE_EXAMPLE);
              setShowExample(true);
            }}
          >
            See example
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} align="right">
        {show === SHOW_RECAPTCHA && (
          <ReCAPTCHA
            sitekey={SITE_KEY}
            onChange={onRecaptchaChange}
          />
        )}
        {show === SHOW_BUTTON && (
          <Button
            onClick={onCheckAutenthication}
            color="primary"
            disabled={!valid || !cookiesContext.cookiesAccepted}>
            Check authentication
          </Button>
        )}
      </Grid>
      <ExamplePicture
        show={showExample}
        onClose={() => setShowExample(false)}
      />
    </>
  );
}
